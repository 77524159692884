/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";
import { BlogIndex as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query BlogIndexQuery {
    craft {
      entry(section: "blogIndex") {
        title
        url
        slug
        ... on Craft_blogIndex_blogIndex_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroLabel
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #featured
          commonFeaturedSubheader
          #blog posts
          commonMoreHeader
        }
      }
      entries(section: "blog") {
        ... on Craft_blog_blog_Entry {
          uid
          title
          postDate
          commonCopy
          url
          blogCategory {
            ... on Craft_blog_Category {
              title
            }
          }
          blogImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          commonFeatured
        }
      }
    }
  }
`;

const previewQuery = `query BlogIndexQuery {
  craft {
    entry(section: "blogIndex") {
      title
      url
      slug
      ... on Craft_blogIndex_blogIndex_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroLabel
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #featured
        commonFeaturedSubheader
        #blog posts
        commonMoreHeader
      }
    }
    entries(section: "blog") {
      ... on Craft_blog_blog_Entry {
        uid
        title
        postDate
        commonCopy
        url
        blogCategory {
          ... on Craft_blog_Category {
            title
          }
        }
        blogImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        commonFeatured
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
    // featured
    commonFeaturedSubheader,
    // blog posts
    commonMoreHeader,
  } = craft.entry;

  const resolveCategories = categories =>
    categories
      .map(c => {
        return c.title;
      })
      .join(", ");

  const articles = craft.entries.map(a => {
    return {
      title: a.title,
      date: a.postDate,
      copy: a.commonCopy,
      url: a.url,
      categories: resolveCategories(a.blogCategory),
      image: resolveImage(a.blogImage),
      featured: a.commonFeatured,
    };
  });

  const isFeatured = array => array.find(({ featured }) => featured === true);

  return {
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    featured: {
      article: isFeatured(articles) || articles[0],
      heading: commonFeaturedSubheader,
    },
    blogPosts: {
      heading: commonMoreHeader,
      articles,
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
